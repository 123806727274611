import { Client } from "./client";
import { Validable } from "./common";
import * as _ from 'lodash';
import { ValidateIf, IsNotEmpty, IsEmail } from 'class-validator';
import { registerDecorator, ValidationOptions, ValidationArguments } from 'class-validator';

const NOTIFICATIONS = [Math.pow(2,0),Math.pow(2,1),Math.pow(2,2),Math.pow(2,3),Math.pow(2,4),Math.pow(2,5), Math.pow(2,6), Math.pow(2,7), Math.pow(2,8)];
const NOTIFICATIONS_ROLES = [Math.pow(2,0),Math.pow(2,1)];

const REFRESH_BUFFER = 10 * 1000; //10 Segundos

export function ConfirmPass(property: string, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'confirmPass',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          const relatedValue = (args.object as any)[relatedPropertyName];
          return value == relatedValue;
        },
      },
    });
  };
}


export class User extends Validable {
  public static PASSWORD_ENTROPY = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{10,}$/;
  validationKey: string = "user";
  public id: number;
  @IsNotEmpty()
  public username: string;

  @ValidateIf(u => !(u.id > 0) || (!!u.password && u.password.length > 0))
  @IsNotEmpty()
  public password: string;

  public new_password: string;

  @ValidateIf(o => o.password )
  @IsNotEmpty()
  @ConfirmPass('password',{message: 'Passwords must match'})
  public password_confirmation: string;

  @ValidateIf(o => o.new_password )
  @IsNotEmpty()
  @ConfirmPass('new_password',{message: 'Passwords must match'})
  public new_password_confirmation: string;

  @IsNotEmpty()
  public firstname: string;

  @IsNotEmpty()
  public lastname: string;


  @IsNotEmpty()
  @IsEmail()
  public email: string;

  @IsNotEmpty()
  public warehouse_id: number;

  public access: string[];
  public deflected: boolean;
  public roles: string[];
  public avatar_url: string;
  public mfa: string;
  public phone: string;
  public password_should_expire: boolean;
  public session_timeout: number;
  public activity_timeout: number;
  public session_created_at: Date;


  public clients: Client[];
  public notifications: {[client_id: number]: number[]};
  public notification_roles: number;
  public _notification_roles_values: number[];
  public active: boolean;
  public qr: string;
  public totp: string;
  public totp_device: string;
  public totp_session: string;
  public totp_code: string;
  public multiple_sessions: boolean;

  constructor(data: any) {
    super(data);
    if (data.clients_users){
      this.notifications = _(data.clients_users).
      keyBy("client_id").
      mapValues((cu: any) => NOTIFICATIONS.filter(f => (f & cu.notifications) == f)).
      value();
	}
	if (data.roles) {
		this._notification_roles_values = NOTIFICATIONS_ROLES.filter(f => (f & data.notification_roles) == f);
	}
    this.initData();
  }

  get notification_roles_values(): number[] {
  	  return this._notification_roles_values;
  }

  set notification_roles_values(value: number[]) {
  	  this._notification_roles_values = value;
  	  this.notification_roles = value.reduce((acc, v) => acc + v, 0);
  }

  private initData() {
    this.clients = this.clients ? this.clients.map(e => new Client(e)) : [];
    this.roles = this.roles || [];
  }

  get full_name(): string {
    return `${this.firstname} ${this.lastname}`;
  }

  get canCategorize(): boolean {
    return this.roles.includes("client") || this.roles.includes("materialist") || this.isSupervisor;
  }

  get isAdmin(): boolean {
    return this.roles.includes("admin");
  }

  get isSupervisor(): boolean {
    return this.roles.includes("supervisor") || this.roles.includes("admin");
  }

  get isClient(): boolean {
    return this.roles.includes("client") || this.roles.includes("client_ro") || this.roles.includes("admin");
  }

  get isOnlyClient(): boolean {
    return (this.roles.includes("client") || this.roles.includes("client_ro")) && !this.roles.includes("admin");
  }

  get isReadOnly(): boolean {
    return this.roles.includes("client_ro");
  }

  get isNaturalClient(): boolean {
    return this.roles.includes("client") || this.roles.includes("client_ro");
  }

  get isMaterialist(): boolean {
    return this.roles.includes("materialist");
  }

  get isInternal(): boolean {
    return this.isAdmin || this.isSupervisor || this.isMaterialist;
  }

  get isParcelSup(): boolean {
    return this.roles.includes("parcel_sup");
  }

  get saveProp(): string[] {
    let p =  super.saveProp.concat(["username", "firstname", "lastname", "email", "warehouse_id", "roles", "client_ids", "notifications","notification_roles", "use_mfa", "password_should_expire", "session_timeout", "active", "mfa", "phone", "activity_timeout", "totp", "totp_device", "multiple_sessions"])
    if (this.password && this.password != '') p.push("password");
    if (!this.parseDataUrl(this.avatar_url)) p.push("avatar");
    return p;
  }

  get client_ids(): any[] {
    return this.clients.map((c: Client) => c.id);
  }

  get role_str(): string {
    return this.roles.join(" ");
  }

  get client_str(): string {
    return this.clients.map((c: Client) => c.name).join(" ");
  }

  get password_str(): string {
    return this.password;
  }

  get canReprint(): boolean {
    return this.access.includes("reprint") || this.isParcelSup || this.isSupervisor;
  }

  get mfa_active(): boolean {
    return this.mfa == "CUSTOM_CHALLENGE";
  }

  set mfa_active(value: boolean) {
    this.mfa = value ? "CUSTOM_CHALLENGE" : null;
  }

  toSaveData(properties: string[] = null) {
    let data = super.toSaveData(properties);
    let urlData = this.parseDataUrl(this.avatar_url);
    if (urlData) {
      const formData: FormData = new FormData();
      formData.append("user[avatar]",
                      this.dataURLtoBlob(urlData.groups["content"], `${urlData.groups["type"]}/${urlData.groups["ext"]}`),
                      `logo_${this.id}.${urlData.groups["ext"]}`);
      _(data).forEach((v: any, k: string) => {
        if (Array.isArray(v))
          v.forEach((t: string) => formData.append(`user[${k}][]`, t));
        else
          formData.append(`user[${k}]`, v);
      });
      return formData;
    } else {
      return data;
    }
  }

  get ms_for_session_to_expire(): number {
    return (this.session_timeout * 1000 * 60) - (+(new Date()) - (+this.session_created_at)) - REFRESH_BUFFER;
  }

  cleanPasswords() {
    this.password = null;
    this.password_confirmation = null;
    this.new_password = null;
    this.new_password_confirmation = null;
  }

  rollback(): any {
    this.password = null;
    this.password_confirmation = null;
    return super.rollback();
  }
}
